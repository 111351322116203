// components
import { Icon } from '../Icon/Icon';
import { StyledBackToTopButton, DummyBackToTopButton } from './back-to-top-button.styles';

// utils
import { useBackToTop } from './use-back-to-top';

export function BackToTopButton({ onClick }: Readonly<{ onClick: () => void }>) {
  const { displayFixed, displayStatic, ref } = useBackToTop({
    offset: -32,
  });

  return (
    <>
      {displayStatic || (
        <StyledBackToTopButton
          isFixed={displayFixed}
          isStatic={false}
          onClick={onClick}
          href="#to-top"
          aria-label="to the top"
        >
          <Icon symbol="chevron-large-up" />
        </StyledBackToTopButton>
      )}
      {displayStatic && (
        <StyledBackToTopButton
          isFixed={false}
          isStatic={displayStatic}
          onClick={onClick}
          href="#to-top"
          aria-label="to the top s"
        >
          <Icon symbol="chevron-large-up" />
        </StyledBackToTopButton>
      )}
      <DummyBackToTopButton ref={ref} />
    </>
  );
}
