export type ICookieType = 'comfort' | 'analysis' | 'marketing';

export class Usercentrics {
  private instance: IUC_UI;
  constructor() {
    if (window['UC_UI']) {
      this.instance = window['UC_UI'];
    } else {
      console.warn('no usercentrics instance prodvided! Not in ssr mode?');
    }
  }

  getCategories(): { slug: string; label: string }[] {
    return this.instance.getSettingsLabels().categories;
  }

  getActiveLanguage(): string {
    return this.instance.getActiveLanguage();
  }

  showModal() {
    this.instance.showFirstLayer();
  }

  enableSetting(type: 'comfort' | 'analysis' | 'marketing') {
    let services: { id: string }[] = [];
    switch (type) {
      case 'comfort':
        services = this.instance
          .getServicesBaseInfo()
          .filter((e) => e.categorySlug === 'functional');
        break;
      case 'analysis':
        services = this.instance
          .getServicesBaseInfo()
          .filter((e) => e.categorySlug.startsWith('customCategory-'));
        break;
      case 'marketing':
        services = this.instance
          .getServicesBaseInfo()
          .filter((e) => e.categorySlug === 'marketing');
        break;
    }

    if (services.length > 0) {
      this.instance.acceptServices(services.map((i) => i.id));
    }
  }

  updateLanguage(lang: string) {
    this.instance.updateLanguage(lang);
  }

  getLabel(type: ICookieType): string | undefined {
    return this.getCategories().find((c) => {
      if (type === 'comfort') {
        return c.slug === 'functional';
      }
      if (type === 'analysis') {
        return c.slug.startsWith('customCategory-');
      }
      return type === c.slug;
    })?.label;
  }
}
