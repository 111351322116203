import styled, { css } from 'styled-components';

export const StyledBackToTopButton = styled.a<{
  isFixed: boolean;
  isStatic: boolean;
}>(({ isFixed, isStatic }) => {
  return css`
    && {
      right: 1.5625rem;
      bottom: calc(-3rem + calc(var(--chat-height, 0px) + var(--chat-controls-height, 0px)));
      opacity: 0;
      ${isStatic ? 'position: absolute;' : 'position: fixed;'}
      ${isStatic && 'bottom: unset;opacity: 1;top: -4.625rem;'}
      ${isFixed && 'opacity: 1'};
      ${isFixed &&
      `transform: translateY(-5rem)`};
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2.625rem;
      width: 2.625rem;
      background-color: var(--gb-brand-primary);
      font-size: 1.3rem;
      z-index: 20;

      transition:
        background-color 0.15s ease-in-out,
        opacity 0.2s cubic-bezier(0.4, 0, 1, 1),
        transform 0.2s cubic-bezier(0.4, 0, 1, 1);

      ${isStatic && 'transition: none;'}

      &:focus,
      &:hover {
        background-color: var(--gb-brand-primary-dark);
      }

      :before {
        content: none;
      }

      i {
        color: var(--white);
      }
    }
  `;
});

export const DummyBackToTopButton = styled.div(() => {
  return css`
    && {
      right: 1.5625rem;
      visibility: hidden;
      position: absolute;
      top: -4.625rem;
      height: 2.625rem;
      width: 2.625rem;
    }
  `;
});
