import { useEffect, useRef, useState } from 'react';

type Element = {
  selector: string;
  cssVarName: string;
  marginBottom?: number;
  offsetBottom?: number;
  dimension: string;
};

const ELEMENTS: Element[] = [
  {
    selector: '#Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window',
    cssVarName: '--chat-height',
    dimension: 'chatWidgetHeight',
    marginBottom: 10,
    offsetBottom: 22,
  },
  {
    selector: '.gtmLcmOuter',
    cssVarName: '--chat-height',
    dimension: 'chatWidgetHeight',
    offsetBottom: 20,
  },
  {
    selector: '#livechat-basetag > div',
    cssVarName: '--chat-controls-height',
    dimension: 'chatControlsHeight',
    offsetBottom: 16,
  },
];

export function useBackToTop({ offset }: { offset: number }) {
  const ref = useRef<HTMLDivElement>(null);
  const [displayFixed, setDisplayFixed] = useState(false);
  const [displayStatic, setDisplayStatic] = useState(true);
  const [dimensions, setDimensions] = useState<{
    chatWidgetHeight?: number;
    chatControlsHeight?: number;
  }>({});

  useEffect(() => {
    const scrollHandler = () => {
      setDisplayFixed(window.scrollY > 300);
    };

    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  useEffect(() => {
    const config = { childList: true, subtree: true };
    let observers: ResizeObserver[] = [];

    const mutationObserver = new MutationObserver((mutationList) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          observers = ELEMENTS.map(handleElement);
        }
      }
    });

    mutationObserver.observe(document.body, config);

    return () => {
      mutationObserver.disconnect();
      for (const observer of observers) {
        observer.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (ref.current === null) return;

    const options = {
      root: null,
      rootMargin: `${
        offset - (dimensions.chatWidgetHeight ?? 0) - (dimensions.chatControlsHeight ?? 0)
      }px 0px`,
      threshold: 1,
    };

    const observer = new IntersectionObserver(([{ isIntersecting }]) => {
      setDisplayStatic(isIntersecting);
    }, options);

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [dimensions.chatControlsHeight, dimensions.chatWidgetHeight, offset]);

  function handleElement({
    cssVarName,
    marginBottom = 0,
    offsetBottom = 0,
    selector,
    dimension,
  }: Element) {
    const root = document.documentElement;
    const chatWidget = document.querySelector(selector);

    const handler = () => {
      let height = chatWidget?.getBoundingClientRect().height ?? 0;
      if (height > 0) {
        height -= marginBottom;
        height += offsetBottom;
      }
      setDimensions((dimensions) => ({ ...dimensions, [dimension]: height }));
      root.style.setProperty(cssVarName, `${height}px`);
    };

    handler();
    const observer = new ResizeObserver(handler);
    if (chatWidget) observer.observe(chatWidget);

    return observer;
  }

  return {
    ref,
    displayFixed,
    displayStatic,
  };
}
