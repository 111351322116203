import { trackTemplate } from '../../utils/tracking';

/**
 * Button click
 * @param eventAction
 * @param eventLabel
 */
export function footerTrackingAction(eventAction, eventLabel) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Footer',
      action: eventAction,
      label: eventLabel,
      value: '0',
    },
  });
}
