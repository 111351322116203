import { THEME_NAMES } from '../../../themes';
import type { PossibleThemeName } from '../../../utils/hooks/use-theme';
import { FontWeights } from './Headline';

/**
 * Get the FontWeight for a Headline
 *
 * @param emphasize Defines if the headline should be emphesized. This has no effect on NORD and KOLO themes!
 * @param theme The name of the Theme (should be the output of `useThemeName()`hook)
 * @param format The used format. Should reflect the value of `format={...}`
 * @param defaultWeight Usually we want headlines to be light if nothing other is specified. This can be overwritten here.
 * @returns The font-weight for the given usecase.
 */

export const getFontWeight = (
  emphasize: boolean,
  theme: PossibleThemeName,
  format: string,
  defaultWeight = FontWeights.light,
) => {
  // Headlines 1-3 should be regular and 4-6 should always be bold on NORD theme
  if (theme === THEME_NAMES.NORD) {
    if (format === 'h1' || format === 'h2' || format === 'h3') {
      return FontWeights.regular;
    }
    return FontWeights.bold;
  }

  // Headlines 1-2 should be bold and 3 should be regular
  if (theme === THEME_NAMES.KOLO) {
    if (format === 'h1' || format === 'h2') return FontWeights.bold;
    if (format === 'h3') return FontWeights.regular;
  }

  // Headlines 1-3 should be light and 4-6 should be bold
  if (theme === THEME_NAMES.TWYFORD) {
    if (format === 'h1' || format === 'h2' || format === 'h3') return FontWeights.light;
    return FontWeights.bold;
  }

  if (emphasize) {
    return FontWeights.bold;
  }

  return defaultWeight;
};
