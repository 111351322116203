import React from 'react';

import { useLocatorConsentTranslations } from '../hooks/use-consent-translations';
import { useUsercentrics } from '../hooks/use-uc-consent';

// Components
import {
  BUTTON_BACKGROUND,
  BUTTON_COLORS,
  BUTTON_TYPES,
  Button,
} from '../../../components/Button/Button';
import { InnerHtml } from '../../../components/InnerHtml/inner-html';

// Styles
import styles from './locator.module.scss';

export function LocatorConsent() {
  const translations = useLocatorConsentTranslations();
  const uc = useUsercentrics();

  if (!translations || !uc) {
    return null;
  }
  return (
    <section className={[styles.consentContainer, styles.consentLocator].join(' ')}>
      <div className={styles.consentBox}>
        <h3>{translations.headline}</h3>
        {<InnerHtml as="div" content={translations.text} />}
        <Button
          className={styles.consentButton}
          type={BUTTON_TYPES.DEFAULT}
          color={BUTTON_COLORS.WHITE}
          background={BUTTON_BACKGROUND.PRIMARY}
          onClick={() => {
            setTimeout(() => {
              uc.enableSetting(translations.type);
            }, 100);
          }}
        >
          {translations.buttonLabel}
        </Button>
      </div>
    </section>
  );
}
